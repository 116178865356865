<!--  -->
<template>
  <div class="uploadData" >
    <el-dialog
        title="提示"
        :visible.sync="isShowAddStoreDialog"
        width="850px"
        :close-on-click-modal="false"  
        class="addStoreDialog"
    >
        <div class="contents-wrap" v-loading="loading">
            <div class="left-wrap-up">
                <div class="type-wrap">
                    <div class="type-item" @click="clickAllImage">
                        <div class="type">全部图片</div>
                    </div>
                    <div class="" v-for="(item,index) in typeList" :key="index">
                        <div class="type-item" >
                            <div class="type">
                                <div class="type-iocn-show" @click="clickShow(index)" >
                                    <i class="icon" :class="curIndex == index? 'el-icon-caret-bottom': 'el-icon-caret-right'" v-show="item.childrenNode.length != 0"></i>
                                </div>
                                <div class="type-name" @click="clickTypeItem(item.data.id)">
                                    {{item.data.classifyName}}
                                </div>
                            </div>
                        </div>
                        <!-- 二级 -->
                        <div class="type-item-2" :class="curIndex == index? 'isActive': ''" v-for="(second,index1) in item.childrenNode" :key="index1">
                            <div class="type-item">
                                <div class="type">
                                    <div class="type-iocn-show" @click="clickShowSecond(index1)" >
                                        <i class="icon el-icon-caret-right" v-show="second.childrenNode.length != 0"></i>
                                    </div>
                                    <div @click="clickTypeItem(second.data.id)">
                                        {{second.data.classifyName}}
                                    </div>
                                </div>
                            </div>
                            <!-- 三级 -->
                            <div class="type-item-3" :class="curIndexSecond == index1? 'isActiveSecond': ''" v-for="(three,index2) in second.childrenNode" :key="index2">
                                <div class="type-item" @click="clickTypeItem(three.data.id)">
                                    <div class="type">{{three.data.classifyName}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-wrap-up">
                <div class="upload-btn">  
                    <div class="btn">
                        <el-upload
                            class="upload-demo"
                            action="/"
                            :file-list="goodsImages"
                            :auto-upload="false"
                            multiple
                            :on-change="uploadChangeMinipro"
                            >
                            <el-button size="small" type="primary">上传图片</el-button>
                        </el-upload>
                        <el-upload class="del"
                                action="https://elk.c-radar.cn/shop/media/uploadVideoUrl"
                                :data="{FoldPath:'上传目录',SecretKey:'安全验证'}"
                                :on-success="handleVideoSuccess"
                                :before-upload="beforeUploadVideo"
                                :show-file-list="false">
                            <el-button size="small" type="primary">上传视频</el-button>
                        </el-upload>
                        
                        <el-button size="small" class="del" @click="delMaterials">删除</el-button>
                    </div>
                    <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全部使用</el-checkbox> -->
                </div>
                <div class="chooseNumber">最多只能选择{{this.graphics}}张</div>
                <div class="type-wrap">
                    <el-checkbox-group v-model="checkedCities" @change="handleCheckedChange" :max="max">
                        <div class="img-wrap">
                            <div class="img-item" v-for="(item,index) in imageList"  :key="index">
                            <el-checkbox  :label="item">
                                    <el-image class="image" :src="item.url" v-if="item.type == 'image'"></el-image>
                                    <video  class="video" :src="item.url" v-if="item.type == 'video'"></video>
                                    <div class="title">{{item.fileName}}</div>
                            </el-checkbox>
                            </div>
                        </div>
                    </el-checkbox-group>
                    <div class="page-wrap">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage4"
                            :page-sizes="[12, 20, 50, 100]"
                            layout="total, sizes, prev, pager, next"
                            :total="totalRow">
                        </el-pagination>
                        <div class="choose">
                            <el-button size="small" type="primary" @click="usingSelectionImages">使用选择的图片</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>   
  </div>
</template>

<script>
import aliOssClient from '@/methods/aliossUpload'
import UUID from '@/methods/uiid'
export default {
    props: {
        graphics: {
            type: Number,
            default: 0
        },
        uploadType: {
            type: String,
            default: ''
        },
    },
    data () {
        return {
            isShowAddStoreDialog: false,
            typeVlue: '',
            imgValue: '',
            classifyValue: '',
            imageList:[],
            typeList: [],
            curIndex: -1,
            isShow: false,
            curIndexSecond: -1,
            isShowSecond: false,
            loading: false,

            currentPage4: 1,
            currentPage: 1,
            pageSize: 12,
            totalRow: 0,

            checkAll: false,
            checkedCities: [],
            isIndeterminate: true,
            goodsImages: [],
            imageChoose: [],
            max: this.graphics,
            chooseImageList: []
        };
    },
    watch: {
        graphics(v) {
            if(v) {
                this.max = v
            }
        }
    },
    mounted(){
        this.getStoriesList();
        this.taxonomicStructure();
    },
    methods: {
        //删除素材
        delMaterials(){
            let id = [];
            this.chooseImageList.forEach(v =>{
                id.push(v.id)
            })
            if(id.length == 0 ){
                this.$message.error("请选择图片")
            }else{
                let params = {
                    ids: id
                }
                this.$API.deleteMediaCatalogName(params).then( res => {
                    if(res.code === 0){
                        this.getStoriesList();
                        this.checkAll = false;
                        this.$message.success("删除素材成功");
                    }
                })
            }
        },
        // 点击列表显示对应的内容
        clickTypeItem(id){
            this.getStoriesList(id)
        },
        clickThreeTypeItem(id){
        },
        // 点击全部图片
        clickAllImage(){
            this.getStoriesList();
        },
        //一级显示隐藏
        clickShow(index){
            this.isShow = !this.isShow;
            if(this.isShow){
                this.curIndex = index;
            }else{
                this.curIndex = -1
            }
        },
        // 二级显示隐藏
        clickShowSecond(index){
            this.isShowSecond = !this.isShowSecond;
            if(this.isShowSecond){
                this.curIndexSecond = index;
            }else{
                this.curIndexSecond = -1
            }
        },
        //获取分类结构
        taxonomicStructure(){
            this.$API.getListCatalog().then( res => {
                if(res.code === 0){
                    this.typeList = res.data
                }
            })
        },
        // 获取素材列表
        getStoriesList(id){
            let params = {
                mediaType: '',
                catalogId: id,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            this.$API.getStoriesList(params).then( res => {
                if(res.code === 0){
                    res.data.list.forEach(v => {
                        v.fileName = v.fileName.substring(0,v.fileName.indexOf("."));
                    })
                    this.imageList = res.data.list;
                    this.totalRow = res.data.pageInfo.totalRow
                }
            })
        },
        //点击选中图片
        usingSelectionImages(){
            this.$emit("selectImages",this.imageChoose,this.uploadType);
            this.isShowAddStoreDialog = false;
            this.checkedCities = []
        },
        //上传视频前回调
        beforeUploadVideo(file) {
            var fileSize = file.size / 1024 / 1024 < 50;
            if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
                this.$message.warning("请上传正确的视频格式")
                return false;
            }
            if (!fileSize) {
                this.$message.warning("视频大小不能超过50MB")
                return false;
            }
        },
        // 上传成功回调
        handleVideoSuccess(response, file, fileList){
            this.loading = true
            this.fileObj = file
            let ossName = UUID.v1()
            aliOssClient.put(`/video/${ossName}`,file.raw,{
                progress:(p)=>{
                    this.progress(p,index)
                }
            }).then(res=>{
                let params = {
                    fileName: file.name,
                    mediaUrl: res.url
                }
                if(res.res.statusCode === 200){
                    this.$API.uploadVideoMaterial(params).then( res => {
                        if(res.code === 0){
                            this.fileObj ={}
                            this.getStoriesList();
                            this.loading = false
                        }
                    })
                }
            })
            .catch((err)=>{
                console.log(err);
                this.$message.warning('上传图片失败')
                return
            })
        },
        // 上传图片
        uploadChangeMinipro(file,fileList){
            this.loading = true
            this.goodsImages = fileList;
            let ossName = UUID.v1()
            aliOssClient.put(`/images/${ossName}`,file.raw,{
                progress:(p)=>{
                    this.progress(p,index)
                }
            }).then(res=>{
                let params = {
                    fileName: file.name,
                    mediaUrl: res.url
                }
                if(res.res.statusCode === 200){
                    this.$API.uploadImageMaterial(params).then( res => {
                        if(res.code === 0){
                            this.getStoriesList();
                            this.goodsImages = []
                            this.loading = false
                        }
                    })
                }
            })
            .catch((err)=>{
                console.log(err);
                this.$message.warning('上传图片失败')
                return
            })
        },
        //全选
        handleCheckAllChange(val) {
            this.checkedCities = val ? this.imageList : [];
            this.isIndeterminate = false;
        },
        //点击选择
        handleCheckedChange(value) {
            this.chooseImageList = value
            if(value.length > this.graphics){
                this.max = this.graphics;
            }else{
                this.imageChoose = value
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.imageList.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.imageList.length;
            }
        },
        // 每页多少条
        handleSizeChange(val) {
            this.pageSize  = val;
            this.getStoriesList();
        },
        // 当前页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getStoriesList();
        },
        //添加分类
        addType(){},
        // 删除分类
        delType(){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'}).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        submitForm(){},
        resetForm(){},
        getDate(){
            this.isShowAddStoreDialog = true
        },
    }
}

</script>
<style lang='scss'>
.uploadData{
    .contents-wrap{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 20px;
        .left-wrap-up{
            width: 25%;
            height: 300px;
            .type-wrap{
                margin-top: 10px;
                .type-item{
                    padding: 0px 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #4386c6;
                    height: 26px;
                    .type{
                        font-size: 14px;
                        cursor: pointer;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .type-iocn-show{
                            width: 20px;
                            .icon{
                                color: #999;
                            }
                        }
                        .type-name{
                            width: 150px;
                        }
                    }
                    .type-icon{
                        display: none;
                       i{
                           font-size: 16px;
                           font-weight: 600;
                           padding-left: 10px;
                           cursor: pointer;
                       } 
                    }
                }
                .type-item:hover{
                    background: #edf6ff;
                    .type-icon{display: block;} 
                }
                .type-item-2{
                    padding-left: 20px;
                    display: none;
                }
                .isActive{
                    display: block;
                }
                .type-item-3{
                    padding-left: 20px;
                    display: none;
                }
                .isActiveSecond{
                    display: block;
                    margin-left: 10px;
                }
            }
        }
        .right-wrap-up{
            width: 65%;
            margin-left: 30px;
            .upload-btn{
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .btn{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .del{
                        margin-left: 20px;
                    }
                    .el-upload-list{
                        display: none;
                    }
                }
            }
            .type-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .img-wrap{
                    width: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-flow: row wrap;
                    box-sizing: border-box;
                    margin-top: 10px;
                    .img-item{
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 10px;
                        margin-right: 15px;
                        // width: calc((100% - 20px)/4);
                        .image{
                            width: 120px;
                            height: 120px;
                        }
                        .video{
                            width: 120px;
                            height: 120px;
                            border: 1px solid #eee;
                        }
                        .title{
                            width: 120px;
                            overflow: hidden;
                            padding-top: 5px;
                            font-size: 14px;
                        }
                        .el-checkbox__input{
                            position: absolute;
                            right: 0px;
                        }
                        .el-checkbox__label{
                            padding-left: 0;
                        }
                    }
                }
                .page-wrap{
                    padding: 20px 0;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
            .chooseNumber{
                font-size: 12px;
                color: #999;
            }
        }
    }
}
</style>